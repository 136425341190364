<!--
  PACKAGE_NAME : src/pages/cc/ivr/dnis/modal-ibg.vue
  FILE_NAME : modal-ibg
  AUTHOR : hmlee
  DATE : 2024-09-02
  DESCRIPTION : 인입그룹 설정 모달
-->
<template>
  <DxPopup
    :show-title="showTitle"
    :title="modal.title"
    :min-width="modal.minWidth"
    :width="modal.width"
    :min-height="modal.minHeight"
    :height="modal.height"
    :drag-enabled="modal.dragEnabled"
    :resize-enabled="modal.resizeEnabled"
    :show-close-button="modal.showCloseButton"
    :hide-on-outside-click="modal.hideOnOutsideClick"
    :visible="isOpen"
    @hiding="closeModal"
  >
    <template #content>
      <div class="flex flex-row">
        <div class="basis-2/5 bg-white relative">
          <div class="flex flex-row absolute top-5 z-50">
            <h2 class="text-lg font-semibold">미설정 인입그룹</h2>
            <DxButton
              template="<span class='mdi mdi-sync'></span>"
              :height="20"
              type="button"
              :hint="$_msgContents('CC.MESSAGE.RESET_IBG', { defaultValue: '인입그룹 설정 초기화' })"
              class="btn_XXXS white outlined ml-2"
              @click="onReset"
            />
          </div>
          <esp-dx-data-grid calss="relative" :data-grid="beforeDataGrid" :ref="beforeDataGrid.refName" @row-click="onClickSelectedRow" />
        </div>
        <div class="basis-1/5 flex flex-col justify-center px-5 space-y-4">
          <DxButton
            template="<span class='mdi mdi-chevron-right'></span>"
            :height="20"
            type="button"
            :hint="$_msgContents('COMPONENTS.ADD_SETTING', { defaultValue: '설정 추가' })"
            :disabled="button.disabled.clickedArrowType === 2"
            class="btn_XXXS white outlined"
            @click="onSetSelectedIbg"
          />
          <DxButton
            template="<span class='mdi mdi-chevron-left'></span>"
            :height="20"
            type="button"
            :hint="$_msgContents('COMPONENTS.REMOVE_SETTING', { defaultValue: '설정 해제' })"
            :disabled="button.disabled.clickedArrowType === 1"
            class="btn_XXXS white outlined"
            @click="onRemoveSelectedIbg"
          />
        </div>
        <div class="basis-2/5 bg-white relative">
          <h2 class="text-lg font-semibold absolute top-5 z-50">설정 인입그룹</h2>
          <esp-dx-data-grid :data-grid="afterDataGrid" :ref="afterDataGrid.refName" @row-click="onClickSelectedRow" />
        </div>
      </div>
    </template>

    <DxToolbarItem
      widget="dxButton"
      toolbar="bottom"
      location="center"
      :visible="true"
      :options="{
        elementAttr: {
          class: 'default filled txt_S medium',
        },
        text: this.$_msgContents('COMPONENTS.SAVE', { defaultValue: '저장' }),
        width: '120',
        height: '40',
        useSubmitBehavior: true,
        onClick: this.saveModal,
      }"
    />
    <DxToolbarItem
      widget="dxButton"
      toolbar="bottom"
      location="center"
      :visible="true"
      :options="{
        elementAttr: {
          class: 'white filled txt_S medium',
        },
        text: this.$_msgContents('COMPONENTS.CANCEL', { defaultValue: '취소' }),
        width: '120',
        height: '40',
        onClick: this.closeModal,
      }"
    />
  </DxPopup>
</template>

<script>
  import { DxPopup, DxToolbarItem } from 'devextreme-vue/popup';
  import { DxButton } from 'devextreme-vue/button';
  import { isSuccess } from '@/plugins/common-lib';
  import CustomStore from 'devextreme/data/custom_store';
  import EspDxDataGrid from '@/components/devextreme/esp-dx-data-grid.vue';

  let vm = null;
  export default {
    components: {
      EspDxDataGrid,
      DxPopup,
      DxToolbarItem,
      DxButton,
    },
    props: {
      isOpen: {
        default: false,
        type: Boolean,
      },
      ibgData: {
        default: null,
        type: Object,
      },
    },
    watch: {},
    computed: {
      /** @description: 모달 타이틀 출력 여부 */
      showTitle() {
        return !!this.modal.title;
      },
    },
    data() {
      return {
        modal: {
          title: this.$_msgContents('CC.WORD.IBG_SETTING', { defaultValue: '인입그룹 설정' }),
          minWidth: '300',
          width: '850',
          minHeight: '300',
          height: '580',
          dragEnabled: true,
          resizeEnabled: true,
          showCloseButton: true,
          hideOnOutsideClick: false,
        },
        button: {
          disabled: {
            clickedArrowType: 0, // 0: 초기화(화살표 버튼 안눌렀을 때), 1: 설정추가(오른쪽화살표), 2: 설정해제(왼쪽화살표)
          },
        },
        ibgSettingChangedRows: [], //설정 인입그룹에 설정 추가된(변경된 로우) 데이터
        dnisIbgList: [], //설정 인입그룹에 추가된 데이터(미설정 인입그룹에서 제외하기 위해 필요)
        beforeDataGrid: {
          refName: 'beforeIbgGrid',
          keyExpr: 'id',
          allowColumnResizing: true, //컬럼 사이즈 허용
          showBorders: false, //border 유무
          showColumnHeaders: true, //컬럼 헤더 유무
          showColumnLines: false, //컬럼 세로선 유무
          showRowLines: true, //컬럼 가로선 유무
          rowAlternationEnabled: false,
          hoverStateEnabled: true,
          dataSource: [],
          //width:'350',     // 주석처리시 100%
          height: '440', // 주석처리시 100%
          apiActionNm: {},
          customEvent: {
            rowClick: true,
          },
          showActionButtons: {
            select: false,
            copy: false,
            delete: false,
          },
          grouping: {
            contextMenuEnabled: false,
            autoExpandAll: false,
            allowCollapsing: false,
            expandMode: 'rowClick', // rowClick or buttonClick
          },
          groupPanel: {
            visible: false,
          },
          columnChooser: {
            enabled: false, // 컬럼 Chooser 버튼 사용유무
          },
          loadPanel: {
            enabled: true, // 로딩바 표시 유무
          },
          sorting: {
            mode: 'multiple', // single multiple
          },
          //scrolling: { // 미사용시 주석처리
          //  mode: 'standard' //스크롤 모드 : ['infinite', 'standard', 'virtual']
          //},
          remoteOperations: {
            // 서버사이드 여부
            filtering: true,
            sorting: true,
            grouping: true,
            paging: true,
          },
          paging: {
            // scrolling 미사용시만 적용됨
            enabled: false,
            pageSize: 10,
            pageIndex: 0, // 시작페이지
          },
          pager: {
            visible: false, //페이저 표시 여부
            showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
            allowedPageSizes: [5, 10, 15, 20], //페이지 사이즈 선택 박스
            displayMode: 'compact', //표시 모드 : ['full', 'compact']
            showInfo: true, //페이지 정보 표시 여부 : full인 경우만 사용 가능
            showNavigationButtons: true, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
          },
          filterRow: {
            visible: true,
          },
          headerFilter: {
            visible: true,
          },
          editing: {
            allowUpdating: false, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
            allowDeleting: false,
            allowAdding: false, // 추가 버튼을 없애고 싶으면 false설정
            mode: 'batch', //수정 모드: ['row', 'cell', 'batch']
            startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
            selectTextOnEditStart: true, //셀 수정시 텍스트 전체 선택 여부
          },
          selecting: {
            mode: 'multiple', //행 단일/멀티 선택 타입 : ['single', 'multiple']
            selectAllMode: 'page', //행 선택 허용 범위 : ['allPages', 'page']
            showCheckBoxesMode: 'always', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
          },
          columns: [
            {
              caption: 'id',
              dataField: 'id',
              alignment: 'center', // left center right
              visible: false,
              allowEditing: false,
              sortOrder: 'none', // acs desc none
              allowHeaderFiltering: false,
              fixed: false, // 컬럼 fix 시 사용
              fixedPosition: 'left', // left or right
            },
            {
              caption: '인입그룹',
              i18n: 'CC.WORD.IBG',
              dataField: 'ibg',
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
            },
            {
              caption: '인입그룹명',
              i18n: 'CC.WORD.IBG_NAME',
              dataField: 'ibgNm',
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
            },
          ],
        },
        afterDataGrid: {
          refName: 'afterIbgGrid',
          keyExpr: 'id',
          allowColumnResizing: true, //컬럼 사이즈 허용
          showBorders: false, //border 유무
          showColumnHeaders: true, //컬럼 헤더 유무
          showColumnLines: false, //컬럼 세로선 유무
          showRowLines: true, //컬럼 가로선 유무
          rowAlternationEnabled: false,
          hoverStateEnabled: true,
          dataSource: [],
          //width:'350',     // 주석처리시 100%
          height: '440', // 주석처리시 100%
          apiActionNm: {},
          customEvent: {
            rowClick: true,
          },
          showActionButtons: {
            select: false,
            copy: false,
            delete: false,
          },
          grouping: {
            contextMenuEnabled: false,
            autoExpandAll: false,
            allowCollapsing: false,
            expandMode: 'rowClick', // rowClick or buttonClick
          },
          groupPanel: {
            visible: false,
          },
          columnChooser: {
            enabled: false, // 컬럼 Chooser 버튼 사용유무
          },
          loadPanel: {
            enabled: true, // 로딩바 표시 유무
          },
          sorting: {
            mode: 'multiple', // single multiple
          },
          //scrolling: { // 미사용시 주석처리
          //  mode: 'standard' //스크롤 모드 : ['infinite', 'standard', 'virtual']
          //},
          remoteOperations: {
            // 서버사이드 여부
            filtering: true,
            sorting: true,
            grouping: true,
            paging: true,
          },
          paging: {
            // scrolling 미사용시만 적용됨
            enabled: false,
            pageSize: 10,
            pageIndex: 0, // 시작페이지
          },
          pager: {
            visible: false, //페이저 표시 여부
            showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
            allowedPageSizes: [5, 10, 15, 20], //페이지 사이즈 선택 박스
            displayMode: 'compact', //표시 모드 : ['full', 'compact']
            showInfo: true, //페이지 정보 표시 여부 : full인 경우만 사용 가능
            showNavigationButtons: true, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
          },
          filterRow: {
            visible: true,
          },
          headerFilter: {
            visible: true,
          },
          editing: {
            allowUpdating: false, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
            allowDeleting: false,
            allowAdding: false, // 추가 버튼을 없애고 싶으면 false설정
            mode: 'batch', //수정 모드: ['row', 'cell', 'batch']
            startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
            selectTextOnEditStart: true, //셀 수정시 텍스트 전체 선택 여부
          },
          selecting: {
            mode: 'multiple', //행 단일/멀티 선택 타입 : ['single', 'multiple']
            selectAllMode: 'page', //행 선택 허용 범위 : ['allPages', 'page']
            showCheckBoxesMode: 'always', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
          },
          columns: [
            {
              caption: 'id',
              dataField: 'id',
              alignment: 'center', // left center right
              visible: false,
              allowEditing: false,
              sortOrder: 'none', // acs desc none
              allowHeaderFiltering: false,
              fixed: false, // 컬럼 fix 시 사용
              fixedPosition: 'left', // left or right
            },
            {
              caption: '인입그룹',
              i18n: 'CC.WORD.IBG',
              dataField: 'ibg',
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
            },
            {
              caption: '인입그룹명',
              i18n: 'CC.WORD.IBG_NAME',
              dataField: 'ibgNm',
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
            },
          ],
        },
      };
    },
    methods: {
      /** @description: 모달 저장 */
      async saveModal() {
        //인입그룹 설정이 없는 경우
        if (this.button.disabled.clickedArrowType === 0) {
          return vm.$_Msg(this.$_msgContents('COMMON.MESSAGE.CMN_NOT_SELECTED', { defaultValue: '대상이 선택되어 있지 않습니다.' }));
        }

        let dnisIbgDatas = [];
        let actionName = '';
        if (this.button.disabled.clickedArrowType === 1) {
          //인입그룹 설정 추가
          dnisIbgDatas = this.ibgSettingChangedRows.map(ibg => {
            return {
              ibgId: ibg.id,
              dnisId: this.ibgData.id,
            };
          });
          actionName = 'CC_IVR_DNIS_IBG_MERGE';
        } else if (this.button.disabled.clickedArrowType === 2) {
          //인입그룹 설정 해제(삭제)
          dnisIbgDatas = this.ibgSettingChangedRows;
          actionName = 'CC_IVR_DNIS_IBG_DELETE';
        }

        const payload = {
          actionname: actionName,
          data: dnisIbgDatas,
          useErrorPopup: true,
        };

        const res = await this.CALL_CC_API(payload);
        if (isSuccess(res)) {
          this.$emit('saveModal');
        }
      },
      /** @description: 모달 닫기 */
      closeModal() {
        //this.initModal();
        this.$emit('closeModal');
      },
      /** @description: DNIS 인입그룹 조회 */
      async selectDnisIbgList() {
        const payload = {
          actionname: 'CC_IVR_DNIS_IBG_LIST',
          data: { dnisId: this.ibgData.id },
          useErrorPopup: true,
        };

        const res = await this.CALL_CC_API(payload);
        let rstData = [];
        if (isSuccess(res)) {
          rstData = res.data.data;
        }
        return rstData;
      },
      /** @description: 미설정 인입그룹 데이터 조회 */
      async selectBeforeDataList(sort = '+id') {
        let vm = this;

        //인입그룹 설정 전 그리드 데이터
        this.beforeDataGrid.dataSource = new CustomStore({
          key: 'id',
          async load(loadOptions) {
            let params = vm.$_getDxDataGridParam(loadOptions);

            if (!params.sort) params.sort = sort;

            //미설정 인입그룹 그리드에서 매핑된 인입그룹 제외
            if (vm.dnisIbgList.length > 0) {
              const dnisIbgIds = vm.dnisIbgList.map(dnisIbg => dnisIbg.ibgId);
              params.id = `<>${dnisIbgIds}`; //ibg id
            }

            params.tenantId = vm.ibgData.tenantId; //센터
            params.viewFl = 'Y';

            const payload = {
              actionname: 'CC_IBG_LIST',
              data: params,
              useErrorPopup: true,
            };

            const res = await vm.CALL_CC_API(payload);
            let rtnData = {
              data: [],
              totalCount: 0,
            };
            if (isSuccess(res)) {
              rtnData = {
                data: res.data.data,
                totalCount: res.data.header.totalCount,
              };
              vm.$refs[vm.beforeDataGrid.refName].totalCount = rtnData.totalCount;
            }
            return rtnData;
          },
        });
      },
      /** @description: 설정 인입그룹 데이터 조회 */
      async selectAfterDataList(sort = '+id') {
        let vm = this;

        //인입그룹 설정 후 그리드 데이터
        this.afterDataGrid.dataSource = new CustomStore({
          key: 'id',
          async load(loadOptions) {
            let params = vm.$_getDxDataGridParam(loadOptions);

            if (!params.sort) params.sort = sort;

            //dnisId로 조회
            params.dnisId = vm.ibgData.id;

            vm.$log.debug(params);
            let payload = {
              actionname: 'CC_IVR_DNIS_IBG_LIST',
              data: params,
              useErrorPopup: true,
            };

            const res = await vm.CALL_CC_API(payload);
            let rtnData = {
              data: [],
              totalCount: 0,
            };
            if (isSuccess(res)) {
              rtnData = {
                data: res.data.data,
                totalCount: res.data.header.totalCount,
              };
              vm.$refs[vm.afterDataGrid.refName].totalCount = rtnData.totalCount;
            }
            return rtnData;
          },
        });
      },
      /** @description: 인입그룹 설정 초기화 */
      onReset(e) {
        //그리드 편집 초기화
        this.$refs[this.beforeDataGrid.refName].getGridInstance.cancelEditData();
        this.$refs[this.afterDataGrid.refName].getGridInstance.cancelEditData();

        //그리도 선택 초기화
        this.$refs[this.beforeDataGrid.refName].getGridInstance.deselectAll();
        this.$refs[this.afterDataGrid.refName].getGridInstance.deselectAll();

        //그리드 초기화
        this.$refs[this.beforeDataGrid.refName].refreshData();
        this.$refs[this.afterDataGrid.refName].refreshData();

        //설정/해제 버튼 초기화
        this.button.disabled.clickedArrowType = 0;

        //설정 인입그룹에 추가된 데이터 초기화
        this.ibgSettingChangedRows = [];
      },
      /** @description: 그리드 클릭시 체크되는 이벤트 */
      onClickSelectedRow(e) {
        const selectedRowKeys = e.component.getSelectedRowKeys();
        const index = selectedRowKeys.indexOf(e.key);

        if (index === -1) {
          //선택되지 않은 경우 추가
          selectedRowKeys.push(e.key);
        } else {
          //선택된 경우 삭제
          selectedRowKeys.splice(index, 1);
        }

        e.component.selectRows(selectedRowKeys);
      },
      /** @description: 인입그룹 설정 추가 */
      onSetSelectedIbg(e) {
        //미설정 인입그룹 선택된 데이터
        const selectedRowsData = this.$refs[this.beforeDataGrid.refName].selectedRowsData;

        if (selectedRowsData.length === 0) {
          return this.$_Msg(this.$_msgContents('COMMON.MESSAGE.CMN_NOT_SELECTED', { defaultValue: '대상이 선택되어 있지 않습니다.' }));
        }

        //설정 추가 버튼 클릭시
        this.button.disabled.clickedArrowType = 1;

        //그리드 초기화
        this.$refs[this.beforeDataGrid.refName].getGridInstance.cancelEditData();
        this.$refs[this.afterDataGrid.refName].getGridInstance.cancelEditData();

        //설정 인입그룹에 추가
        this.ibgSettingChangedRows = selectedRowsData;
        selectedRowsData.forEach(row => {
          const rowIdx = this.$refs[this.afterDataGrid.refName].getGridInstance.addRow();
        });
        selectedRowsData.forEach((obj, index) => {
          Object.entries(obj).forEach(([key, value]) => {
            this.$refs[this.afterDataGrid.refName].getGridInstance.cellValue(index, key, value);
          });
        });

        let ibgSettingDatas = this.$refs[this.afterDataGrid.refName].getGridInstance;
        ibgSettingDatas = this.$refs[this.afterDataGrid.refName].getGridInstance.getVisibleRows();

        //미설정 인입그룹에서 선택한 데이터 삭제
        let keys = selectedRowsData.map(item => {
          return this.$refs[this.beforeDataGrid.refName].getGridInstance.getRowIndexByKey(item.id);
        });
        keys.forEach(keyIdx => {
          this.$refs[this.beforeDataGrid.refName].getGridInstance.deleteRow(keyIdx);
        });
      },
      /** @description: 인입그룹 설정 해제 */
      onRemoveSelectedIbg(e) {
        //설정 인입그룹 선택된 데이터
        const selectedRowsData = this.$refs[this.afterDataGrid.refName].selectedRowsData;

        if (selectedRowsData.length === 0) {
          return this.$_Msg(this.$_msgContents('COMMON.MESSAGE.CMN_NOT_SELECTED', { defaultValue: '대상이 선택되어 있지 않습니다.' }));
        }

        //설정 해제 버튼 클릭시
        this.button.disabled.clickedArrowType = 2;

        //그리드 초기화
        this.$refs[this.beforeDataGrid.refName].getGridInstance.cancelEditData();
        this.$refs[this.afterDataGrid.refName].getGridInstance.cancelEditData();

        //미설정 인입그룹에 추가
        this.ibgSettingChangedRows = selectedRowsData;
        selectedRowsData.forEach(row => {
          this.$refs[this.beforeDataGrid.refName].getGridInstance.addRow();
        });
        selectedRowsData.forEach((obj, index) => {
          Object.entries(obj).forEach(([key, value]) => {
            this.$refs[this.beforeDataGrid.refName].getGridInstance.cellValue(index, key, value);
          });
        });

        //설정 인입그룹에서 선택한 데이터 삭제
        let keys = selectedRowsData.map(item => {
          return this.$refs[this.afterDataGrid.refName].getGridInstance.getRowIndexByKey(item.id);
        });
        keys.forEach(keyIdx => {
          this.$refs[this.afterDataGrid.refName].getGridInstance.deleteRow(keyIdx);
        });
      },
      /** @description: 라이프사이클 created시 호출되는 메서드 */
      async createdData() {
        this.dnisIbgList = await this.selectDnisIbgList(); //설정 인입그룹에 추가된 데이터(미설정 인입그룹에서 제외하기 위해 필요)
        await this.selectAfterDataList(); //설정 인입그룹 조회
        await this.selectBeforeDataList(); //미설정 인입그룹 조회
      },
      /** @description: 라이프사이클 mounted시 호출되는 메서드 */
      async mountedData() {},
      /** @description : 라이프사이클 destroyed시 호출되는 메서드 */
      destroyedData() {},
    },
    created() {
      this.createdData();
    },
    mounted() {
      this.mountedData();
    },
    destroyed() {
      this.destroyedData();
      //this.$off('input');
    },
  };
</script>

<style scoped></style>